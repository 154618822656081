

$('document').ready(function () {

	$("#js-sticky").headroom();

	$('.js-burger').click(function(){
		$('.js-nav').toggleClass('is-open');
	});

	$('.js-cross').click(function(){
		$('.js-nav').removeClass('is-open');
	});

	$('.js-box-tile__description').responsiveEqualHeightGrid();

	$('.js-scroll-to').click(function(e){
		var id = $(this).attr("href")
		e.preventDefault();
		$('html, body').animate({
		    scrollTop: $(id).offset().top + 48
		}, 500);
	});
	

	// browser window scroll (in pixels) after which the "back to top" link is shown
	var offset = 300,
		//browser window scroll (in pixels) after which the "back to top" link opacity is reduced
		//offset_opacity = 1200,
		//duration of the top scrolling animation (in ms)
		scroll_top_duration = 700,
		//grab the "back to top" link
		$back_to_top = $('.js-top');

	//hide or show the "back to top" link
	$(window).scroll(function(){
		( $(this).scrollTop() > offset ) ? $back_to_top.addClass('is-visible') : $back_to_top.removeClass('is-visible');
		/*if( $(this).scrollTop() > offset_opacity ) { 
			$back_to_top.addClass('cd-fade-out');
		}*/
	});

	//smooth scroll to top
	$back_to_top.on('click', function(event){
		event.preventDefault();
		$('body,html').animate({
			scrollTop: 0 ,
		 	}, scroll_top_duration
		);
	});

});


	

